<script setup>
import {toRef} from 'vue'

import {useCarousel} from '@/composables/useCarousel.js'

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  transition: {
    type: String,
    default: 'bounce',
    validator: (transition) => ['bounce', 'fade'].includes(transition),
  },
  items: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Object,
    default: () => ({}),
  },
  showIndicators: {
    type: Boolean,
    default: true,
  },
  showPrev: {
    type: Boolean,
    default: true,
  },
  showNext: {
    type: Boolean,
    default: true,
  },
  circleIndicators: {
    type: Boolean,
    default: false,
  },
  captionAlign: {
    type: String,
    default: 'center',
    validator: (captionAlign) => ['left', 'center', 'right'].includes(captionAlign),
  },
})

const emit = defineEmits(['update', 'updated'])

const carouselElement = ref(null)

const {slides, activeIndex, isOnlyOneSlide, prev, next, slideTo} = useCarousel(
  carouselElement,
  toRef(props, 'items'),
  toRef(props, 'options'),
  toRef(props, 'options'),
)

function redirectToBanner(url) {
  if (!url) return
  window.open(url, '_blank')
}
</script>

<style lang="scss">
@import 'assets/css/components/global/nu-carousel.css';

.carousel {
  .carousel-inner {
    .banner-embedded-video {
      .embedded-video {
        @include for-non-mobile-only {
          max-height: 300px;
        }
      }
      @include for-non-mobile-only {
        max-height: 300px;
      }
    }
    .banner-image {
      margin-bottom: 8px;
      cursor: pointer;
      @include for-non-mobile-only {
        max-height: 300px;
        object-fit: cover;
        margin-bottom: 0;
      }
    }
    .banner-embedded-video-wrapper {
      width: 100%;
      @include for-non-mobile-only {
        height: 300px;
      }
      cursor: pointer;
      z-index: 10;
      position: absolute;
    }
  }
}
</style>

<template>
  <div
    :class="{'carousel-fade': transition === 'fade', 'circle-indicators': circleIndicators}"
    :id="id"
    class="carousel nu-carousel slide"
    ref="carouselElement"
  >
    <div
      class="carousel-indicators"
      v-if="showIndicators && !isOnlyOneSlide"
    >
      <button
        :aria-current="index === 0 ? 'true' : 'false'"
        :aria-label="'Slide ' + (index + 1)"
        :class="{active: index === activeIndex}"
        :data-bs-target="id"
        type="button"
        :key="`carousel-indicators-${index}`"
        v-for="(slideData, index) in slides"
        @click.prevent="slideTo(index)"
      ></button>
    </div>
    <div class="carousel-inner">
      <div
        :class="{active: index === 0}"
        class="carousel-item"
        :key="`carousel-item-${index}`"
        v-for="(slide, index) in slides"
      >
        <slot
          :index="index"
          :item="slide"
          name="item"
        >
          <img
            :alt="slide.imageAlt"
            :src="slide.image"
            class="d-block w-100 banner-image"
            v-if="slide.image"
            @click="redirectToBanner(slide.url)"
          />
          <template v-else>
            <div
              class="banner-embedded-video-wrapper"
              @click="redirectToBanner(slide.url)"
            ></div>
            <NuHtmlLoader
              :html="slide.embeddedCode"
              class="banner-embedded-video"
            ></NuHtmlLoader>
          </template>
          <div
            :class="{
              'caption-left': captionAlign === 'left',
              'caption-center': captionAlign === 'center',
              'caption-right': captionAlign === 'right',
            }"
            class="carousel-caption d-none d-md-block"
          >
            <h5>{{ slide.title }}</h5>
            <p>{{ slide.description }}</p>
          </div>
        </slot>
      </div>
    </div>
    <slot
      :event="prev"
      name="prev-btn"
      v-if="!isOnlyOneSlide"
    >
      <button
        class="carousel-control-prev"
        type="button"
        v-if="showPrev"
        @click="prev"
      >
        <slot name="prev-content">
          <span
            aria-hidden="true"
            class="carousel-control-prev-icon"
          ></span>
          <span class="visually-hidden">{{ $t('nu_carousel.prev_button_aria_label') }}</span>
        </slot>
      </button>
    </slot>
    <slot
      :event="next"
      name="next"
      v-if="!isOnlyOneSlide"
    >
      <button
        class="carousel-control-next"
        type="button"
        v-if="showNext"
        @click="next"
      >
        <slot name="next-content">
          <span
            aria-hidden="true"
            class="carousel-control-next-icon"
          ></span>
          <span class="visually-hidden">{{ $t('nu_carousel.next_button_aria_label') }}</span>
        </slot>
      </button>
    </slot>
  </div>
</template>
