// This composables is depended on the bootstrap carousel in window scope.

import {computed, getCurrentInstance, onMounted, onUnmounted, ref} from 'vue'

export function useCarousel(component, items, options) {
  const carouselElement = component
  const slides = ref(items)
  const isOnlyOneSlide = computed(() => slides.value.length === 1)
  const activeIndex = ref(0)
  const {emit} = getCurrentInstance()

  let carouselInstance = null

  const initializeCarousel = () => {
    const defaultOptions = {
      wrap: false,
      touch: false,
      ride: false,
      pause: false,
      keyboard: true,
      interval: 5000,
    }

    carouselInstance = new window.bootstrap.Carousel(carouselElement.value, {...defaultOptions, ...options})

    carouselElement.value.addEventListener('slide.bs.carousel', (data) => {
      activeIndex.value = data.to
      emit('update', {
        active: data.relatedTarget.dataset.bsTarget,
        direction: data.direction,
        to: data.to,
        from: data.from,
      })
    })

    carouselElement.value.addEventListener('slid.bs.carousel', (data) => {
      emit('updated', {
        active: data.relatedTarget.dataset.bsTarget,
        direction: data.direction,
        to: data.to,
        from: data.from,
      })
    })
  }

  const disposeCarousel = () => {
    carouselInstance.dispose()
  }

  onMounted(() => {
    initializeCarousel()
  })

  onUnmounted(() => {
    disposeCarousel()
  })

  const prev = () => {
    carouselInstance.prev()
  }

  const next = () => {
    carouselInstance.next()
  }

  const slideTo = (index) => {
    carouselInstance.to(index)
  }

  return {slides, activeIndex, isOnlyOneSlide, prev, next, slideTo}
}
